import React from 'react'
import {graphql, useStaticQuery} from 'gatsby'
import {Text5} from '../components/shared'
import styled from 'styled-components'

const Terms = () => {
  const data = useStaticQuery(
    graphql`
      query TermsQuery {
        legal: datoCmsLegal {
          termsOfServiceNode {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    `
  )
  return (
    <TermsContainer>
      <Text5 dangerouslySetInnerHTML={{__html: data.legal.termsOfServiceNode.childMarkdownRemark.html}}/>
    </TermsContainer>
  )
}

const TermsContainer = styled.div`
  padding: 48px;
`

export default Terms
